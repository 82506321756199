import React, { useState, useEffect, useContext } from 'react';
import {
  Table, Tag, Button, Spin, Row, Col, Divider,
} from 'antd';
import Axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { convertToDatetimeBE, convertToDatetimeFullBE } from '../../utils';
import { Link } from 'react-router-dom';

import { MainContext } from '../../context/main';
import {
  getPage, getFilter, getSort, perPage, defaultPageSize, pageSizeOptions, showSizeChanger,
} from '../../utils/table';
import { chan } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import ExportExcel from '../../component/ExportExcel';
import DrawerExport from '../../component/DrawerExport';
import DrawerExportDaily from '../../component/DrawerExportDaily';
import Detail from './detail';

const columns = (setTransactionId) => [
  {
    title: 'ลำดับ',
    key: 'index',
    dataIndex: 'index',
  },
  {
    title: 'ประเภทรถ',
    key: 'vehTypeCode',
    dataIndex: 'vehTypeCode',
    render: (v) => {
      const vehicleTypeName = _.get(
        _.find(vehicleTypeData, (o) => o.id === v),
        'name',
        '',
      );
      return vehicleTypeName;
    },
  },
  {
    title: 'ทะเบียน',
    key: 'plate1',
    dataIndex: 'plate1',
    // sorter: true,
    // width: '20%',
    render: (plate1, allData) => (
      <>
        {`${plate1} ${_.get(allData, 'plate2', '')}`}
        <br />
        {_.get(allData, 'offRegDesc', '')}
      </>
    ),
  },
  {
    title: 'หมายเลขอ้างอิง 1',
    key: 'reference_1',
    dataIndex: 'reference_1',
    // sorter: true,
    // width: '20%',
  },
  {
    title: 'หมายเลขอ้างอิง 2',
    key: 'reference_2',
    dataIndex: 'reference_2',
  },
  {
    title: 'ช่องทางการทำรายการ',
    key: 'transaction_channel',
    dataIndex: 'transaction_channel',
    render: (v, allData) => {
      let channel;

      switch (v) {
        case 'mobile':
          channel = 'Mobile Application';
          break;
        case 'scb easy':
          channel = 'SCB Easy App';
          break;
        case 'kiosk':
          channel = 'ตู้รับชำระภาษี';
          break;
        default:
          break;
      }

      const kioskSN = _.get(allData, ['kiosk_id', 'serial_number'], '');

      const prefix = v === 'kiosk' ? 'ชำระที่ตู้หมายเลข' : 'พิมพ์ที่ตู้หมายเลข';

      return (
        <>
          {`${channel || 'ไม่มีข้อมูล'}`}
          <br />
          {`${
            kioskSN ? `${prefix} ${kioskSN}` : 'ไม่มีข้อมูลการทำรายการที่ตู้'
            }`}
        </>
      );
    },
  },
  // {
  //   title: 'เลขประจําตัวประชาชนเจ้าของรถ',
  //   key: 'ownIdNo',
  //   dataIndex: 'ownIdNo',
  // },
  {
    title: 'ช่องทางการรับป้ายภาษี',
    key: 'printFlag',
    dataIndex: 'printFlag',
    render: (v) => (v ? 'ตู้ชำระภาษี' : 'ไปรษณีย์'),
  },
  {
    title: 'รหัสตู้',
    key: 'kiosk_id.kiosk_code',
    dataIndex: 'kiosk_id.kiosk_code'
  },
  {
    title: 'ช่องทางการชำระเงิน',
    key: 'confirmation_id',
    dataIndex: 'confirmation_id',
    render: (confirmationId, o) => {
      let statusPayTaxColor = 'orange';
      let statusPayTaxText = 'รอการชำระ';
      if (_.get(confirmationId, ['channel'])) {
        statusPayTaxColor = 'green';
        const key = _.get(confirmationId, ['channel']);
        statusPayTaxText = `${_.get(chan, [key], 'ไม่ระบุ')}`;
        // statusPayTaxText = `ชำระแล้ว / ${_.get(chan, [key], 'ไม่ระบุ')}`;
      } else if (
        moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
          moment().format('YYYY-MM-DD'),
        )
      ) {
        statusPayTaxColor = 'red';
        statusPayTaxText = 'เลยกำหนดเวลาชำระ';
      }
      return <Tag color={statusPayTaxColor}>{statusPayTaxText}</Tag>;
    },
  },
  {
    title: 'สถานะ',
    key: 'is_success',
    dataIndex: 'is_success',
    render: (isSuccess, o) => {
      let statusColor = 'orange';
      let statusText = 'รอดำเนินการ';
      if (isSuccess) {
        statusColor = 'green';
        statusText = 'สำเร็จ';
      } else if (
        moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
          moment().format('YYYY-MM-DD'),
        )
        || _.get(o, ['confirmation_id'])
      ) {
        statusColor = 'red';
        statusText = 'ไม่สำเร็จ';
      }
      return <Tag color={statusColor}>{statusText}</Tag>;
    },
  },
  {
    title: 'เลขคุมเครื่องหมาย',
    key: 'printlog[0].kioskPaper_reference',
    dataIndex: 'printlog[0].kioskPaper_reference'
  },
  {
    title: 'วันที่ทำรายการ',
    key: 'created',
    dataIndex: 'created',
    sorter: true,
    defaultSortOrder: 'descend',
    render: (v) => convertToDatetimeBE(v),
  },
  {
    title: '',
    key: 'id',
    dataIndex: 'id',
    fixed: 'right',
    render: (v, o) => {
      return (
        <span>
          <Link
            onClick={(e) => {
              e.preventDefault();
              setTransactionId(v);
            }}
          >
            รายละเอียด
          </Link>
          {(_.get(o, ['printFlag']) && _.get(o, ['receipt', 'id'])) && (
            <>
              <Divider type="vertical" />
              <a
                target="_blank"
                href={`${process.env.REACT_APP_API}/receipt/ref/${_.get(o, [
                  'reference_1'
                ])}`}
              >
                ใบเสร็จรับเงิน
              </a>
            </>
          )}
        </span>
      );
    },
    width: '195px',
  },
];

const columnsWithPost = (setTransactionId) => [
  {
    title: 'ลำดับ',
    key: 'index',
    dataIndex: 'index',
  },
  {
    title: 'ประเภทรถ',
    key: 'vehTypeCode',
    dataIndex: 'vehTypeCode',
    render: (v) => {
      const vehicleTypeName = _.get(
        _.find(vehicleTypeData, (o) => o.id === v),
        'name',
        '',
      );
      return vehicleTypeName;
    },
  },
  {
    title: 'ทะเบียน',
    key: 'plate1',
    dataIndex: 'plate1',
    // sorter: true,
    // width: '20%',
    render: (plate1, allData) => (
      <>
        {`${plate1} ${_.get(allData, 'plate2', '')}`}
        <br />
        {_.get(allData, 'offRegDesc', '')}
      </>
    ),
  },
  {
    title: 'หมายเลขอ้างอิง 1',
    key: 'reference_1',
    dataIndex: 'reference_1',
    // sorter: true,
    // width: '20%',
  },
  {
    title: 'หมายเลขอ้างอิง 2',
    key: 'reference_2',
    dataIndex: 'reference_2',
  },
  {
    title: 'ช่องทางการทำรายการ',
    key: 'transaction_channel',
    dataIndex: 'transaction_channel',
    render: (v, allData) => {
      let channel;

      switch (v) {
        case 'mobile':
          channel = 'Mobile Application';
          break;
        case 'scb easy':
          channel = 'SCB Easy App';
          break;
        case 'kiosk':
          channel = 'ตู้รับชำระภาษี';
          break;
        default:
          break;
      }

      const kioskSN = _.get(allData, ['kiosk_id', 'serial_number'], '');

      const prefix = v === 'kiosk' ? 'ชำระที่ตู้หมายเลข' : 'พิมพ์ที่ตู้หมายเลข';

      return (
        <>
          {`${channel || 'ไม่มีข้อมูล'}`}
          <br />
          {`${
            kioskSN ? `${prefix} ${kioskSN}` : 'ไม่มีข้อมูลการทำรายการที่ตู้'
            }`}
        </>
      );
    },
  },
  // {
  //   title: 'เลขประจําตัวประชาชนเจ้าของรถ',
  //   key: 'ownIdNo',
  //   dataIndex: 'ownIdNo',
  // },
  {
    title: 'ช่องทางการรับป้ายภาษี',
    key: 'printFlag',
    dataIndex: 'printFlag',
    render: (v) => (v ? 'ตู้ชำระภาษี' : 'ไปรษณีย์'),
  },
  {
    title: 'สถานะการจัดส่งไปรษณีย์',
    key: 'dlt_post_process_flag',
    dataIndex: 'dlt_post_process_flag',
    render: (value, all) => (
      <>
        {`${value}`}
        <br />
        {_.get(all, 'dlt_post_tracking_no', '')}
      </>
    ),
  },
  {
    title: 'รหัสตู้',
    key: 'kiosk_id.kiosk_code',
    dataIndex: 'kiosk_id.kiosk_code'
  },
  {
    title: 'ช่องทางการชำระเงิน',
    key: 'confirmation_id',
    dataIndex: 'confirmation_id',
    render: (confirmationId, o) => {
      let statusPayTaxColor = 'orange';
      let statusPayTaxText = 'รอการชำระ';
      if (_.get(confirmationId, ['channel'])) {
        statusPayTaxColor = 'green';
        const key = _.get(confirmationId, ['channel']);
        statusPayTaxText = `${_.get(chan, [key], 'ไม่ระบุ')}`;
        // statusPayTaxText = `ชำระแล้ว / ${_.get(chan, [key], 'ไม่ระบุ')}`;
      } else if (
        moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
          moment().format('YYYY-MM-DD'),
        )
      ) {
        statusPayTaxColor = 'red';
        statusPayTaxText = 'เลยกำหนดเวลาชำระ';
      }
      return <Tag color={statusPayTaxColor}>{statusPayTaxText}</Tag>;
    },
  },
  {
    title: 'สถานะ',
    key: 'is_success',
    dataIndex: 'is_success',
    render: (isSuccess, o) => {
      let statusColor = 'orange';
      let statusText = 'รอดำเนินการ';
      if (isSuccess) {
        statusColor = 'green';
        statusText = 'สำเร็จ';
      } else if (
        moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
          moment().format('YYYY-MM-DD'),
        )
        || _.get(o, ['confirmation_id'])
      ) {
        statusColor = 'red';
        statusText = 'ไม่สำเร็จ';
      }
      return <Tag color={statusColor}>{statusText}</Tag>;
    },
  },
  {
    title: 'เลขคุมเครื่องหมาย',
    key: 'printlog[0].kioskPaper_reference',
    dataIndex: 'printlog[0].kioskPaper_reference'
  },
  {
    title: 'วันที่ทำรายการ',
    key: 'created',
    dataIndex: 'created',
    sorter: true,
    defaultSortOrder: 'descend',
    render: (v) => convertToDatetimeBE(v),
  },
  {
    title: '',
    key: 'id',
    dataIndex: 'id',
    fixed: 'right',
    render: (v, o) => {
      return (
        <span>
          <Link
            onClick={(e) => {
              e.preventDefault();
              setTransactionId(v);
            }}
          >
            รายละเอียด
          </Link>
          {(_.get(o, ['printFlag']) && _.get(o, ['receipt', 'id'])) && (
            <>
              <Divider type="vertical" />
              <a
                target="_blank"
                href={`${process.env.REACT_APP_API}/receipt/ref/${_.get(o, [
                  'reference_1'
                ])}`}
              >
                ใบเสร็จรับเงิน
              </a>
            </>
          )}
        </span>
      );
    },
    width: '195px',
  },
];

export default (props) => {
  const { request } = useContext(MainContext);

  const [visibleExportExcelModal, setVisibleExportExcelModal] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    // pageSize: perPage,
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [drawerExportVisible, setDrawerExportVisible] = useState(false);
  const [drawerExportDailyVisible, setDrawerExportDailyVisible] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  useEffect(() => {
    fetchData()
  }, [props.type])

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/transactions', {
        ...params,
        ...searchData,
        select: [
          'reference_1',
          'plate1',
          'plate2',
          'is_success',
          'vehTypeCode',
          'offRegDesc',
          'printFlag',
          'confirmation_id',
          'reference_2',
          'ownIdNo',
          'transaction_channel',
          'dlt_post_process_flag',
          'dlt_post_tracking_no'
        ],
        includes: ['confirmed', 'kiosk', 'invoice', 'receipt', 'printlog'],
        // transaction_channel: props.type,
        transaction_type: 'TAX'
      });
      const totalData = _.get(res, ['total_data'], 0);

      const result = _.get(res, ['results'], []);

      setData(result);
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  useEffect(() => {
    fetchData({
      // ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const toggleExportExcelModal = () => {
    setVisibleExportExcelModal(!visibleExportExcelModal);
  };

  const handleDownload = (filename = 'report.xlsx', page, per_page = 1000) => request.download('/transactions/export', filename, {
    ...getPage(pagination),
    ...sorter,
    ...searchData,
    page,
    per_page,
    // transaction_channel: props.type,
    transaction_type: 'TAX'
  });

  const handleExcelSummaryDownload = async (
    filename = 'report.xlsx',
    params,
  ) => {
    setSummaryLoading(true);

    await request.download('/transactions/report', filename, {...params, transaction_channel: props.type, transaction_type: 'TAX'});
    setSummaryLoading(false);
  };

  const handleExcelSummaryDailyDownload = async (
    filename = 'report.xlsx',
    params,
  ) => {
    setSummaryLoading(true);

    await request.download('/transactions/report-daily', filename, {...params, transaction_channel: props.type, transaction_type: 'TAX'});
    setSummaryLoading(false);
  };

  const handleOpenDrawerExport = () => {
    setDrawerExportVisible(true);
  };
  const handleCloseDrawerExport = () => {
    setDrawerExportVisible(false);
  };

  const handleOpenDrawerDailyExport = () => {
    setDrawerExportDailyVisible(true);
  };
  const handleCloseDrawerDailyExport = () => {
    setDrawerExportDailyVisible(false);
  };

  return (
    <Spin spinning={loading}>
      <ExportExcel
        visible={visibleExportExcelModal}
        handleOk={toggleExportExcelModal}
        handleCancel={toggleExportExcelModal}
        handleDownload={handleDownload}
        total={_.get(pagination, 'total', 0)}
      />
      <DrawerExport
        visible={drawerExportVisible}
        onClose={handleCloseDrawerExport}
        handleExcelSummaryDownload={handleExcelSummaryDownload}
        confirmLoading={summaryLoading}
      />
      <DrawerExportDaily
        visible={drawerExportDailyVisible}
        onClose={handleCloseDrawerDailyExport}
        handleExcelSummaryDailyDownload={handleExcelSummaryDailyDownload}
        confirmLoading={summaryLoading}
      />
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        <div>
          <Button
            style={{ margin: '0 0 0 16px' }}
            icon="export"
            onClick={toggleExportExcelModal}
          >
            Export ข้อมูลการทํารายการ
          </Button>
          <Button
            style={{ margin: '0 0 0 16px' }}
            icon="export"
            onClick={handleOpenDrawerExport}
          >
            Export ข้อมูลสรุปการทํารายการ
          </Button>
          <Button
            style={{ margin: '0 0 0 16px' }}
            icon="export"
            onClick={handleOpenDrawerDailyExport}
          >
            Export ข้อมูลรายวัน
          </Button>
        </div>
      </HeaderBlock>

      <Table
        columns={props.type == "mobile" ? columnsWithPost(setTransactionId) :  columns(setTransactionId)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={pagination}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
      />
      <Detail
        transactionId={transactionId}
        setTransactionId={setTransactionId}
      />
    </Spin>
  );
};
