import React, { useState, useEffect, useContext } from 'react';
import {
  Table, Tag, Button, Spin, Row, Col, Divider, Input
} from 'antd';
import Axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import { convertToDatetimeBE, convertToDateBE } from '../../utils'
import { Link } from 'react-router-dom';

import { MainContext } from '../../context/main';
// import {
//   getPage, getFilter, getSort, perPage,
// } from '../../utils/table';
import {
  getFilter,
  getSort,
  getPage,
  getColumnSearchProps,
  perPage,
  defaultPageSize,
  pageSizeOptions,
  showSizeChanger,
} from '../../utils/table';
import { chan } from '../../utils';
import { HeaderBlock } from '../../style/global';
import FilterModal from '../../component/FilterModal';
import vehicleTypeData from '../../utils/staticData/vehicleTypeData';
import ExportExcel from '../../component/ExportExcel';
import DrawerExport from '../../component/DrawerExport';
import DrawerExportDaily from '../../component/DrawerExportDaily';
import Detail from './detail';

const isAllow = (menuPermissions, targetmenuPermissions) => {
  const thisMenuId = "1"
  const permission = _.find(menuPermissions, { menuId: thisMenuId });
  return permission && permission.canManage;
};

export default (props) => {
  const { request, userProfile } = useContext(MainContext);

  const [visibleExportExcelModal, setVisibleExportExcelModal] = useState(false);
  const [data, setData] = useState([]);
  const [visible, setVisible] = useState(false);
  const [searchData, setSearchData] = useState({});
  const [sorter, setSorter] = useState({
    sort: { key: 'created', desc: true },
  });
  const [pagination, setPagination] = useState({
    defaultPageSize,
    pageSizeOptions,
    showSizeChanger,
  });
  const [loading, setLoading] = useState(false);
  const [summaryLoading, setSummaryLoading] = useState(false);
  const [drawerExportVisible, setDrawerExportVisible] = useState(false);
  const [drawerExportDailyVisible, setDrawerExportDailyVisible] = useState(false);
  const [transactionId, setTransactionId] = useState('');

  const [editingId, setEditingId] = useState(null);
  const [newValue, setNewValue] = useState("");

  const handleEdit = (id, oldValue) => {
    setEditingId(id); // Set the ID of the item being edited
    setNewValue(oldValue); // Set the initial value to the current value
  };

  const saveEditedValue = (id, newValue) => {
    onSaveEMS(newValue, id);
    setEditingId(null); // Reset the editing state
  };

  useEffect(() => {
    fetchData()
  }, [props.type])

  const fetchData = async (params = {}) => {
    try {
      setLoading(true);
      const res = await request.get('/transactions', {
        ...params,
        ...searchData,
        select: [
          'reference_1',
          'plate1',
          'plate2',
          'is_success',
          'vehTypeCode',
          'offRegDesc',
          'printFlag',
          'confirmation_id',
          'reference_2',
          'ownIdNo',
          'transaction_channel',
          'dlt_post_process_flag',
          'dlt_post_tracking_no',
          'dlt_post_send_date',
        ],
        includes: ['confirmed', 'kiosk', 'invoice', 'receipt', 'printlog'],
        // transaction_channel: props.type,
        transaction_type: 'TAX'
      });
      const totalData = _.get(res, ['total_data'], 0);

      const result = _.get(res, ['results'], []);

      setData(result);
      setPagination({ ...pagination, total: totalData, current: params.page, pageSize: params.per_page });
    } catch (error) {
      console.error(error);
    } finally {
      setLoading(false);
    }
  };

  const handleTableChange = (paginationChange, filters, sorterChange) => {
    const pager = { ...pagination };
    pager.current = paginationChange.current;

    setPagination(pager);
    setSorter(getSort(sorterChange));

    fetchData({
      ...getFilter(filters),
      ...getSort(sorterChange),
      ...getPage(paginationChange),
    });
  };

  const columns = (setTransactionId, onSaveEMS) => [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      width: "70px"
    },
    {
      title: 'หมายเลขอ้างอิง 1',
      key: 'reference_1',
      dataIndex: 'reference_1',
      // sorter: true,
      // width: '20%',
    },
    {
      title: 'หมายเลขอ้างอิง 2',
      key: 'reference_2',
      dataIndex: 'reference_2',
    },
    {
      title: 'ทะเบียนรถ',
      key: 'plate1',
      dataIndex: 'plate1',
      // sorter: true,
      // width: '20%',
      render: (plate1, allData) => (
        <>
          {`${plate1} ${_.get(allData, 'plate2', '')} ${_.get(allData, 'offRegDesc', '')} รย.${parseInt(_.get(allData, 'vehTypeCode', ''), 10)}`}
          {/* <br />
          {_.get(allData, 'offRegDesc', '')} */}
        </>
      ),
    },
    // {
    //   title: 'จังหวัด',
    //   key: 'offRegDesc',
    //   dataIndex: 'offRegDesc',
    // },
    {
      title: 'ชื่อ-สกุล ผู้ทำรายการ',
      key: 'created_byname',
      dataIndex: 'created_byname',
      render: (createdBy, o) => {
        let createName = '';
        if (!_.isNil(createdBy)) {
          createName = createdBy;
        } else {
          createName = "ไม่ระบุ";
        }
        return createName;
      }
    },
    {
      title: 'ช่องทางการทำรายการ',
      key: 'transaction_channel',
      dataIndex: 'transaction_channel',
      render: (v, allData) => {
        let channel;

        switch (v) {
          case 'mobile':
            channel = 'แอพ DLT Vehicle Tax Plus';
            break;
          case 'scb easy':
            channel = 'SCB Easy App';
            break;
          case 'kiosk':
            channel = 'ตู้รับชำระภาษี';
            break;
          default:
            break;
        }

        const kioskSN = _.get(allData, ['kiosk_id', 'serial_number'], '');

        const prefix = v === 'kiosk' ? 'ชำระที่ตู้หมายเลข' : 'พิมพ์ที่ตู้หมายเลข';

        return (
          <>
            {`${channel || 'ไม่มีข้อมูล'}`}
            {/* <br />
            {`${
              kioskSN ? `${prefix} ${kioskSN}` : 'ไม่มีข้อมูลการทำรายการที่ตู้'
              }`} */}
          </>
        );
      },
    },
    // {
    //   title: 'เลขประจําตัวประชาชนเจ้าของรถ',
    //   key: 'ownIdNo',
    //   dataIndex: 'ownIdNo',
    // },
    {
      title: 'ช่องทางรับเอกสาร',
      key: 'printFlag',
      dataIndex: 'printFlag',
      render: (v) => (v ? 'ตู้ชำระภาษี' : 'ไปรษณีย์'),
    },
    {
      title: 'วันเวลาทำรายการ',
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: 'จำนวนเงิน (บาท)',
      key: 'amount',
      dataIndex: 'amount',
      render: (text) => parseFloat(text).toLocaleString('en-US', { minimumFractionDigits: 2, maximumFractionDigits: 2 }),
    },
    {
      title: 'ชำระผ่าน',
      key: 'confirmation_id',
      dataIndex: 'confirmation_id',
      render: (confirmationId, o) => {
        let statusPayTaxColor = 'orange';
        let statusPayTaxText = 'รอการชำระ';
        if (_.get(confirmationId, ['channel'])) {
          statusPayTaxColor = 'green';
          const key = _.get(confirmationId, ['channel']);
          statusPayTaxText = `${_.get(chan, [key], 'ไม่ระบุ')}`;
          // statusPayTaxText = `ชำระแล้ว / ${_.get(chan, [key], 'ไม่ระบุ')}`;
        } else if (
          moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
            moment().format('YYYY-MM-DD'),
          )
        ) {
          statusPayTaxColor = 'red';
          statusPayTaxText = 'เลยกำหนดเวลาชำระ';
        }
        return <Tag color={statusPayTaxColor}>{statusPayTaxText}</Tag>;
      },
    },
    {
      title: 'สถานะชำระเงิน',
      key: 'confirmation_id_payment',
      dataIndex: 'confirmation_id',
      render: (confirmationId, o) => {
        let statusPayTaxColor = 'orange';
        let statusPayTaxText = 'รอการชำระ';
        if (_.get(confirmationId, ['channel'])) {
          statusPayTaxColor = 'green';
          const key = _.get(confirmationId, ['channel']);
          statusPayTaxText = `ชำระเงินแล้ว`;
          // statusPayTaxText = `ชำระแล้ว / ${_.get(chan, [key], 'ไม่ระบุ')}`;
        } else if (
          moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
            moment().format('YYYY-MM-DD'),
          )
        ) {
          statusPayTaxColor = 'red';
          statusPayTaxText = 'เลยกำหนดเวลาชำระ';
        }
        return <Tag color={statusPayTaxColor}>{statusPayTaxText}</Tag>;
      },
    },
    {
      title: 'สถานะการต่อภาษี',
      key: 'is_success',
      dataIndex: 'is_success',
      render: (isSuccess, o) => {
        let statusColor = 'orange';
        let statusText = 'รอดำเนินการ';
        if (isSuccess) {
          statusColor = 'green';
          statusText = 'สำเร็จ';
        } else if (
          moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
            moment().format('YYYY-MM-DD'),
          )
          || _.get(o, ['confirmation_id'])
        ) {
          statusColor = 'red';
          statusText = 'ไม่สำเร็จ';
        }
        return <Tag color={statusColor}>{statusText}</Tag>;
      },
    },
    {
      title: 'สถานะการพิมพ์',
      key: 'printlog[0].status',
      dataIndex: 'printlog[0].status',
      render: (status, o) => {
        const LastPrintLogArray = _.get(o, ['printlog', 'length'], 0) - 1;
        status = _.get(o, ['printlog', LastPrintLogArray, 'status'], null);
        let statusColor = 'orange';
        let statusText = 'ยังไม่พิมพ์';
        if (status == "success") {
          statusColor = 'green';
          statusText = 'พิมพ์แล้ว';
        } else if (status == "on_process") {
          statusColor = 'orange';
          statusText = 'กำลังพิมพ์';
        }
        // also show พิมพ์แล้ว if there is a tracking number
        const trackingNumber = _.get(o, ['dlt_post_tracking_no']);
        if (trackingNumber) {
          statusColor = 'green';
          statusText = 'พิมพ์แล้ว';
        }
        return <Tag color={statusColor}>{statusText}</Tag>;
      },
    },
    {
      title: 'วันเวลาพิมพ์เครื่องหมายฯ',
      key: 'printlog[0].created',
      dataIndex: 'printlog[0].created',
      render: (v, o) => {
        const LastPrintLogArray = _.get(o, ['printlog', 'length'], 0) - 1;
        v = _.get(o, ['printlog', LastPrintLogArray, 'created'], null);
        // if there is a tracking number and dlt_post_send_date then show dlt_post_send_date
        const trackingNumber = _.get(o, ['dlt_post_tracking_no']);
        const dlt_post_send_date = _.get(o, ['dlt_post_send_date']);
        if (trackingNumber && dlt_post_send_date) {
          const [day, month, year] = dlt_post_send_date.split('/').map(Number);
          const date = new Date(Date.UTC(year, month - 1, day));
          const formattedDate = date.toISOString().split('T')[0] + 'T18:00:00.000+07:00';
          const senddate = convertToDateBE(formattedDate);
          return senddate;
        }

        if (v === null || v === undefined) {
          return 'ไม่มีข้อมูล';
        }
        return convertToDatetimeBE(v);
      },
    },
    {
      title: 'หมายเลข EMS',
      key: 'printFlag_2',
      dataIndex: 'printFlag',
      render: (printFlag, o) => {
        if (printFlag) {
          return 'ไม่มีข้อมูล';
        }
        const trackingNumber = _.get(o, ['dlt_post_tracking_no']);
        const id = _.get(o, ['id']);
        if (trackingNumber) {
          if (editingId === id) {
            return (
              <>
                <Input
                  value={newValue}
                  onChange={e => setNewValue(e.target.value)}
                  style={{ width: "200px" }}
                />
                <br />
                <Button style={{ color: "white", backgroundColor: "#1890ff" }} onClick={() => saveEditedValue(id, newValue)}>บันทึก</Button>
                <Button onClick={() => setEditingId(null)}>ยกเลิก</Button>
              </>
            );
          } else {
            return (
              <>
                {trackingNumber}
                {isAllow(userProfile.menuPermissions) && (
                  <Button icon="edit" style={{ marginLeft: "10px" }} onClick={() => handleEdit(id, trackingNumber)}></Button>
                )}
              </>
            );
          }
        }
        if (isAllow(userProfile.menuPermissions)) {
          return (
            <>
              <Input.Search
                placeholder="EMS"
                enterButton="บันทึก"
                size="small"
                onSearch={value => onSaveEMS(value, id)}
              />
            </>
          );
        } else {
          return null; // Or you can return some default value if user doesn't have permission
        }
      },
    },
    // {
    //   title: 'เลขคุมเครื่องหมาย',
    //   key: 'printlog[0].kioskPaper_reference',
    //   dataIndex: 'printlog[0].kioskPaper_reference'
    // },
    {
      title: isAllow(userProfile.menuPermissions) ? 'จัดการ' : '',
      key: 'id',
      dataIndex: 'id',
      fixed: 'right',
      render: (v, o) => {
        return (
          isAllow(userProfile.menuPermissions) ? (
            <span>
              <Link
                to="#"
                onClick={(e) => {
                  e.preventDefault();
                  setTransactionId(v);
                }}
              >
                รายละเอียด
              </Link>
              {(_.get(o, ['receiptDetail', 'link']) && _.get(o, ['receiptDetail', 'link']).length > 1) && (
                <>
                  <Divider type="vertical" />
                  <span style={{ color: '#0f0' }}>
                    <a
                      target="_blank"
                      href={_.get(o, ['receiptDetail', 'link'])}
                      style={{ color: '#0c0' }}
                    >
                      ใบเสร็จรับเงิน
                    </a>
                  </span>
                </>
              )}
              {(_.get(o, ['printFlag']) && _.get(o, ['receipt', 'id']) && _.get(o, ['transaction_channel']) == "kiosk") && (
                <>
                  <br />
                  <Divider type="vertical" />
                  <span style={{ color: '#0f0' }}>
                    <a
                      target="_blank"
                      href={`${process.env.REACT_APP_API}/receipt/ref/${_.get(o, [
                        'reference_1'
                      ])}`}
                      style={{ color: '#0c0' }}
                    >
                      ใบเสร็จรับเงิน(รูปแบบเก่า)
                    </a>
                  </span>
                </>
              )}
            </span>
          ) : ''
        );
      },
      width: isAllow(userProfile.menuPermissions) ? (window.innerWidth < 500 ? '130px' : '195px') : '0px',

    },
  ];



  const columnsWithPost = (setTransactionId) => [
    {
      title: 'ลำดับ',
      key: 'index',
      dataIndex: 'index',
      width: "70px"
    },
    {
      title: 'ประเภทรถ',
      key: 'vehTypeCode',
      dataIndex: 'vehTypeCode',
      render: (v) => {
        const vehicleTypeName = _.get(
          _.find(vehicleTypeData, (o) => o.id === v),
          'name',
          '',
        );
        return vehicleTypeName;
      },
    },
    {
      title: 'ทะเบียน',
      key: 'plate1',
      dataIndex: 'plate1',
      // sorter: true,
      // width: '20%',
      render: (plate1, allData) => (
        <>
          {`${plate1} ${_.get(allData, 'plate2', '')}`}
          <br />
          {_.get(allData, 'offRegDesc', '')}
        </>
      ),
    },
    {
      title: 'หมายเลขอ้างอิง 1',
      key: 'reference_1',
      dataIndex: 'reference_1',
      // sorter: true,
      // width: '20%',
    },
    {
      title: 'หมายเลขอ้างอิง 2',
      key: 'reference_2',
      dataIndex: 'reference_2',
    },
    {
      title: 'ช่องทางการทำรายการ',
      key: 'transaction_channel',
      dataIndex: 'transaction_channel',
      render: (v, allData) => {
        let channel;

        switch (v) {
          case 'mobile':
            channel = 'Mobile Application';
            break;
          case 'scb easy':
            channel = 'SCB Easy App';
            break;
          case 'kiosk':
            channel = 'ตู้รับชำระภาษี';
            break;
          default:
            break;
        }

        const kioskSN = _.get(allData, ['kiosk_id', 'serial_number'], '');

        const prefix = v === 'kiosk' ? 'ชำระที่ตู้หมายเลข' : 'พิมพ์ที่ตู้หมายเลข';

        return (
          <>
            {`${channel || 'ไม่มีข้อมูล'}`}
            <br />
            {`${kioskSN ? `${prefix} ${kioskSN}` : 'ไม่มีข้อมูลการทำรายการที่ตู้'
              }`}
          </>
        );
      },
    },
    // {
    //   title: 'เลขประจําตัวประชาชนเจ้าของรถ',
    //   key: 'ownIdNo',
    //   dataIndex: 'ownIdNo',
    // },
    {
      title: 'ช่องทางการรับป้ายภาษี',
      key: 'printFlag',
      dataIndex: 'printFlag',
      render: (v) => (v ? 'ตู้ชำระภาษี' : 'ไปรษณีย์'),
    },
    {
      title: 'สถานะการจัดส่งไปรษณีย์',
      key: 'dlt_post_process_flag',
      dataIndex: 'dlt_post_process_flag',
      render: (value, all) => (
        <>
          {`${value}`}
          <br />
          {_.get(all, 'dlt_post_tracking_no', '')}
        </>
      ),
    },
    {
      title: 'รหัสตู้',
      key: 'kiosk_id.kiosk_code',
      dataIndex: 'kiosk_id.kiosk_code'
    },
    {
      title: 'ช่องทางการชำระเงิน',
      key: 'confirmation_id_channel',
      dataIndex: 'confirmation_id',
      render: (confirmationId, o) => {
        let statusPayTaxColor = 'orange';
        let statusPayTaxText = 'รอการชำระ';
        if (_.get(confirmationId, ['channel'])) {
          statusPayTaxColor = 'green';
          const key = _.get(confirmationId, ['channel']);
          statusPayTaxText = `${_.get(chan, [key], 'ไม่ระบุ')}`;
          // statusPayTaxText = `ชำระแล้ว / ${_.get(chan, [key], 'ไม่ระบุ')}`;
        } else if (
          moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
            moment().format('YYYY-MM-DD'),
          )
        ) {
          statusPayTaxColor = 'red';
          statusPayTaxText = 'เลยกำหนดเวลาชำระ';
        }
        return <Tag color={statusPayTaxColor}>{statusPayTaxText}</Tag>;
      },
    },
    {
      title: 'สถานะ',
      key: 'is_success',
      dataIndex: 'is_success',
      render: (isSuccess, o) => {
        let statusColor = 'orange';
        let statusText = 'รอดำเนินการ';
        if (isSuccess) {
          statusColor = 'green';
          statusText = 'สำเร็จ';
        } else if (
          moment(moment(o.created).format('YYYY-MM-DD')).isBefore(
            moment().format('YYYY-MM-DD'),
          )
          || _.get(o, ['confirmation_id'])
        ) {
          statusColor = 'red';
          statusText = 'ไม่สำเร็จ';
        }
        return <Tag color={statusColor}>{statusText}</Tag>;
      },
    },
    {
      title: 'เลขคุมเครื่องหมาย',
      key: 'printlog[0].kioskPaper_reference',
      dataIndex: 'printlog[0].kioskPaper_reference',
      render: (v,o) => {
        const LastPrintLogArray = _.get(o, ['printlog', 'length'], 0) - 1;
        const LastPrintLog = _.get(o, ['printlog', LastPrintLogArray, 'kioskPaper_reference'], null);
        v = LastPrintLog;
        if (v === null || v === undefined) {
          return 'ไม่มีข้อมูล';
        }
        return v;
      }
    },
    {
      title: 'วันที่ทำรายการ',
      key: 'created',
      dataIndex: 'created',
      sorter: true,
      defaultSortOrder: 'descend',
      render: (v) => convertToDatetimeBE(v),
    },
    {
      title: '',
      key: 'id',
      dataIndex: 'id',
      fixed: 'right',
      render: (v, o) => {
        return (
          <span>
            <Link
              to="#"
              onClick={(e) => {
                e.preventDefault();
                setTransactionId(v);
              }}
            >
              จัดการ
            </Link>
            {(_.get(o, ['printFlag']) && _.get(o, ['receipt', 'id']) && _.get(o, ['transaction_channel'])) == "kiosk" && (
              <>
                <Divider type="vertical" />
                <a
                  target="_blank"
                  href={`${process.env.REACT_APP_API}/receipt/ref/${_.get(o, [
                    'reference_1'
                  ])}`}
                >
                  ใบเสร็จรับเงิน
                </a>
              </>
            )}
          </span>
        );
      },
      width: '195px',
    },
  ];



  useEffect(() => {
    fetchData({
      // ...getPage(pagination),
      ...sorter,
    });
  }, [searchData]);

  const handleSearch = (value) => {
    pagination.current = 1;
    setSearchData({ ..._.omitBy(value, (o) => _.isNil(o) || _.isEmpty(o)) });

    setVisible(false);
  };

  const handleCancel = () => {
    setVisible(false);
  };

  const handleOpenModal = () => {
    setVisible(true);
  };

  const toggleExportExcelModal = () => {
    setVisibleExportExcelModal(!visibleExportExcelModal);
  };

  const handleDownload = (filename = 'report.xlsx', page, per_page = 1000) => request.download('/transactions/export', filename, {
    ...getPage(pagination),
    ...sorter,
    ...searchData,
    page,
    per_page,
    // transaction_channel: props.type,
    transaction_type: 'TAX'
  });

  const handleExcelSummaryDownload = async (
    filename = 'report.xlsx',
    params,
  ) => {
    setSummaryLoading(true);

    await request.download('/transactions/report', filename, { ...params, transaction_channel: props.type, transaction_type: 'TAX' });
    setSummaryLoading(false);
  };

  const handleExcelSummaryDailyDownload = async (
    filename = 'report.xlsx',
    params,
  ) => {
    setSummaryLoading(true);

    await request.download('/transactions/report-daily', filename, { ...params, transaction_channel: props.type, transaction_type: 'TAX' });
    setSummaryLoading(false);
  };

  const handleOpenDrawerExport = () => {
    setDrawerExportVisible(true);
  };
  const handleCloseDrawerExport = () => {
    setDrawerExportVisible(false);
  };

  const handleOpenDrawerDailyExport = () => {
    setDrawerExportDailyVisible(true);
  };
  const handleCloseDrawerDailyExport = () => {
    setDrawerExportDailyVisible(false);
  };

  const onSaveEMS = async (EMSNO, id) => {
    const data = { EMSNO }

    // console.log(data);
    const res = request.post(`/transactions/saveEMSNo/${id}`, { EMSNO })
      .then((res) => {
        if (_.get(res, ['success'])) {
          fetchData();
        }
      });
  };

  return (
    <Spin spinning={loading}>
      <HeaderBlock style={{ fontSize: '20px', fontWeight: 'bold' }}>
        สอบถามประวัติชำระภาษีรถประจำปีผ่าน DLT Vehicle Tax Plus และตู้ชำระภาษีประจำปี
      </HeaderBlock>
      <ExportExcel
        visible={visibleExportExcelModal}
        handleOk={toggleExportExcelModal}
        handleCancel={toggleExportExcelModal}
        handleDownload={handleDownload}
        total={_.get(pagination, 'total', 0)}
      />
      <DrawerExport
        visible={drawerExportVisible}
        onClose={handleCloseDrawerExport}
        handleExcelSummaryDownload={handleExcelSummaryDownload}
        confirmLoading={summaryLoading}
      />
      <DrawerExportDaily
        visible={drawerExportDailyVisible}
        onClose={handleCloseDrawerDailyExport}
        handleExcelSummaryDailyDownload={handleExcelSummaryDailyDownload}
        confirmLoading={summaryLoading}
      />
      <HeaderBlock>
        <Button type="primary" onClick={handleOpenModal}>
          ค้นหา
        </Button>
        {isAllow(userProfile.menuPermissions) && (
          <div>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={toggleExportExcelModal}
            >
              Export ข้อมูลการทํารายการ
            </Button>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleOpenDrawerExport}
            >
              Export ข้อมูลสรุปการทํารายการ
            </Button>
            <Button
              style={{ margin: '0 0 0 16px' }}
              icon="export"
              onClick={handleOpenDrawerDailyExport}
            >
              Export ข้อมูลรายวัน
            </Button>
          </div>
        )}
      </HeaderBlock>

      <Table
        columns={props.type == "mobile" ? columns(setTransactionId, onSaveEMS) : columns(setTransactionId, onSaveEMS)}
        rowKey={(record) => record.id}
        dataSource={data}
        pagination={{ ...pagination }}
        onChange={handleTableChange}
        scroll={{ x: 'max-content' }}
      />
      <FilterModal
        visible={visible}
        handleSearch={handleSearch}
        handleCancel={handleCancel}
      />
      <Detail
        transactionId={transactionId}
        setTransactionId={setTransactionId}
      />
    </Spin>
  );
};
